import React from 'react';
import './companies.css'

const companies = () => {
  return (
    <section className ="c-wrapper">
    <div className="paddings innerWidth flexCenter c-container">
        <img src="./equinix.png" alt="" />
        <img src="./prologis.png" alt="" />
        <img src="./realty.png" alt="" />
        <img src="./tower.png" alt="" />
    </div>
    </section>
    
  )
};

export default companies;
